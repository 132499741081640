.email-extension {
    &-container {
        box-sizing: border-box;
        padding: 0px 12px;
        padding-top: 19px;
    }

    &-icon {
        height: 35px;
        width: 35px;
        object-fit: contain;
        cursor: pointer;
    }
}