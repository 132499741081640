.tab-berichten {
    &-container {
        height: calc(100vh - 312px);
        width: 100%;
    }

    &-document {
        &-container {
            height: 100%;
            width: 325px;
            border-radius: 10px;
            border: solid 1px RGB(212, 212, 212);
            background: white;
            flex-shrink: 0;
            box-sizing: border-box;
            padding: 19px 12px;
        }
        
        &-item {
            width: 100%;
            height: 49px;
            border-radius: 5px;
            background-color: RGB(240, 241, 242);
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            box-sizing: border-box;
            padding: 0px 19px;
        }

        &-notif {
            height: 21px;
            width: 21px;
            border-radius: 10px;
            background-color: red;
        }
    }

    &-messages {
        &-container {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            border: solid 1px RGB(212, 212, 212);
            background: white;
            margin-left: 14px;
        }

        &-view {
            &-container {
                height: calc(100% - 88px);
                width: 100%;
                box-sizing: border-box;
                padding: 19px 29px;
            }

            &-header {
                height: 49px;
                width: 100%;
                background-color: RGB(240, 241, 242);
            }

            &-sub {
                height: calc(100% - 49px);
                width: 100%;
                overflow-y: auto;
            }

            &-item {
                width: 448px;
                box-sizing: border-box;
                padding: 13px;
                min-height: 60px;

                &-left {
                    @extend .tab-berichten-messages-view-item;
                    border-radius: 0 10px 10px 10px;
                    background-color: RGB(69, 84, 93);
                }

                &-right {
                    @extend .tab-berichten-messages-view-item;
                    border-radius: 10px 0px 10px 10px;
                    background-color: RGB(46, 160, 180);
                }
            }
        }

        &-input {
            &-container {
                height: 88px;
                width: 100%;
                box-sizing: border-box;
                padding: 0px 29px;
                display: flex;
                align-items: center;
            }

            &-bar {
                height: 45px;
                width: 100%;
                margin-right: 19px;
                background-color: RGB(240, 241, 242);
                border-radius: 5px;
                display: flex;
            }

            &-emoji {
                height: 45px;
                width: 45px;
                cursor: pointer;
            }

            &-field {
                height: 100%;

                &::placeholder {
                    color: RGB(155, 155, 155);
                }
            }

            &-button { 
                height: 45px;
                width: 45px;
                border-radius: 5px;
                cursor: pointer;
                background-color: RGB(46, 160, 180);

                &-icon {
                    height: 50%;
                    width: 50%;
                    object-fit: contain;
                }
            }
        }
    }
}