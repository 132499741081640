.cookies-text-main {
  margin-top: 5px !important;
}

.text-cookies {
  font-family: 'Vietnam';
  font-size: 14px;
  font-weight: 600;
  color: #45545d;
  margin-right: 30px;
  text-align: justify;
}
.cookie-policy-banner {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  font-family: 'Vietnam';
  position: fixed;
  z-index: 2000000;
  bottom: 0px;
  background-color: #ffffff;
  box-shadow: 2px -1px 5px #ada9a9;
}
.cookie-policy-cookie-icon {
  background-image: url('../../assets/images/choose/cookies.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 50px;
}

.title-in-cookies {
  font-size: 20px;
  font-weight: 800;
}

.btn-accept {
  cursor: pointer;
  font-size: 12px!important;
  height: 36px;
  padding: 10px 0;
  color: #fff;
  background-color: #2ea0b4;
  min-width: 142px;
  font-family: Vietnam;
  font-weight: 600;
  text-transform: none;
  margin-right: 15px;
}

.btn-reject {
  cursor: pointer;
  height: 36px;
  padding: 10px 0;
  color: #45545d;
  background-color: #f0f1f2;
  min-width: 142px;
  font-family: Vietnam;
  font-weight: 600;
  text-transform: none;
  margin-top: 15px;
  font-size: 12px!important;
}
