.dropdown {
    height: 36px;
    width: 171px;
    box-sizing: border-box;
    padding: 0px 9px;
    background-color: white;
    border: solid 1px rgb(204, 204, 204);
    border-radius: 5px;
    outline: none;

    &-container {
        width: 171px;
        height: auto;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
    }

    &-expand {
        width: 99%;
        background-color: white;
        border: solid 1px rgb(204, 204, 204);
        border-radius: 5px;
        position: absolute;
        top: 36px;
        left: 0%;
        overflow: hidden;
        transform-origin: top;

        &-item {
            height: 36px;
            width: 100%;
            box-sizing: border-box;
            padding: 0px 9px;

            &:hover {
                background: RGB(234, 234, 234);
            }
        }
    }

    &-building {
        &-icon {
            height: 15px;
            width: 15px;
            object-fit: contain;
            flex-shrink: 0;
        }
    }

    &-arrow {
        &-icon {
            height: 10px;
            width: 10px;
            object-fit: contain;
            flex-shrink: 0;
        }
    }
}
