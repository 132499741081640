.custom-switch {
    height: 100%;
    width: 100%;
    border-radius: 15px;
    background-color: rgba(45, 160, 179, 0.12);
    border: solid 1px #C8C8C8;
    position: relative;
    display: flex;
    align-items: center;
    
    &-container {
        height: 11px;
        width: 21px;
        cursor: pointer;
    }

    &-ball {
        height: 11px;
        width: 11px;
        border-radius: 10px;
        border: solid 2px #2DA0B3;
        background-color: #2DA0B3;
        position: absolute;
        left: -1px;
    }
}