.message-item {
    &-container {
        width: 527px;
        box-sizing: border-box;
        padding: 13px 18px;
        padding-bottom: 25px;
        min-height: 50px;
    }

    &-box {
        &-left {
            border-radius: 0 10px 10px 10px;
            background-color: rgb(69, 84, 93);
        }

        &-right {
            border-radius: 10px 0px 10px 10px;
            background-color: rgb(46, 160, 180);
        }
    }
}