.progress-bar {
    &-container {
        height: 65px;
        width: 100%;
    }

    &-main {
        height: 5px;
        width: 100%;
        border-radius: 2px;
        background-color: RGBA(69, 84, 93, 0.08);
        overflow: hidden;
        position: relative;

        &-layer {
            height: 100%;
            width: 100%;
            background-color: RGB(46, 160, 180);
            position: absolute;
            left:0px;
            top: 0px
        }
    }

    &-close {
        height: 12px;
        width: 12px;
        object-fit: contain;
        cursor: pointer;
    }
}