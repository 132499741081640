@import '../assets/styles/scss/design';

@import './message_panel/message_panel';
@import './message_panel/message_contact/message_contact';
@import './message_panel/message_item/message_item';
@import './document_panel/document_panel';
@import './projecten/projecten';
@import '../components/dropdown/dropdown';
@import '../components/custom_switch/custom_switch';
@import '../views/aankoper/tab.documenten';
@import '../views/aankoper/tab.berichten';
@import '../views/aankoper/tab.eenheid';
@import '../views/aankoper/tab.historiek';
@import '../views/aankoper/tab.informatie';
@import '../views/aankoper/aankoper';
@import '../views/projecten/components/unit_document_dropdown';
@import '../views/dashboard/Dashboard.view';
@import "./auth/forgot_password/forgot_password";
@import "./auth/reset_password/reset_password";
@import "./aankoper/emails/email_extension";
@import "../components/error_modal/error_modal";
@import "../components/progress_bar/progress_bar";
@import "./dashboard/website/project_detail/project_detail";

// body {
//   overflow: hidden;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  // -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
  // -webkit-text-fill-color: white !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-right-color: #fff;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: #fff;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: #fff;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-bottom-color: #fff;
}
.dd {
  position: relative !important;
  z-index: 3;
  width: 85px !important;
}

.rc-tooltip-inner {
  background-color: #fff;
  color: #46535c;
  font-size: 15px;
  padding: 20px 30px;
}

.font-color {
  &-black {
    color: black;
  }
  &-darkBleu {
    color: var(--darkBleu);
  }

  &-white {
    color: white;
  }

  &-grey {
    color: #9b9b9b;
  }

  &-dark-grey {
    color: #4a4a4a;
  }

  &-blue-grey {
    color: #45545d;
  }

  &-blue {
    color: #2da0b3;
  }

  &-red {
    color: RGB(212, 0, 0);
  }
}

.background-color {
  &-white {
    background: #ffffff;
  }

  &-faded-faint-grey {
    background: #f4f6f9;
  }

  &-light-blue-grey {
    background: #808a90;
  }

  &-dark-grey {
    background: #46535c;
  }

  &-light-grey {
    background-color: RGB(248, 248, 248);
  }

  &-transparent {
    background-color: transparent;
  }
}

.container-full {
  height: 100vh;
  width: 100vw;
}

.link-underline-darkBlue {
  color: var(--darkBleu);

  text-decoration: underline;
}
input::-webkit-calendar-picker-indicator {
  opacity: 100;
}
input[list] {
  margin-left: auto;
  margin-right: auto;

  max-width: 500px;
  background: #fff;
  padding: 20px 20px 20px 20px;
  font: 12px Arial, Helvetica, sans-serif;
  color: #666;
}
input[list] > option {
  background: #fff;
}

.focused-input {
  &:focus {
    border-bottom: solid 1px #9b9b9b;
  }
}
.setting-project- {
  vertical-align: baseline !important;
}
.setting-project-:hover {
  vertical-align: baseline !important;
}
.dotted-image-container img {
  margin: 0px !important;
  height: 18px !important;
}

.input-no-arrows {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

input::placeholder {
  color: #696969;
}

.document-panel-input{
  border-bottom: unset;
}
.lineHeight34{
  line-height: 28px;
}
.wordBreakAll{
  word-break: break-all;
}
.profile-details-container{
  word-break: break-word;
  overflow-wrap: break-word;
}