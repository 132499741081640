.tab-documenten {
    &-container {
        height: calc(100vh - 312px);
        width: 100%;
    }

    &-tab {
        &-container {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &-notif {
            height: 9px;
            width: 9px;
            background-color: RGB(212, 0, 0);
            border-radius: 9px;
            position: absolute;
            top: 60%;
            right: calc(100% + 5px);
        }
    }

    &-upload {
        &-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &-button {
            height: 36px;
            width: 156px;
            background-color: RGB(240, 241, 242);
            border-radius: 5px;
            cursor: pointer; 
        }

        &-text {
            &-container {
                direction: rtl;
            }

            &-image {
                height: 45px;
                width: 32px;
                object-fit: contain;
                position: absolute;
                left: calc(100% + 9px);
                top: 60%;
            }
        }
    }

    &-box {
        overflow-y: auto;
        height: 100%;
        width: 100%;
        border: solid 1px RGB(212, 212, 212);
        background-color: white;
        border-radius: 10px;
        margin-top: 7px;
        box-sizing: border-box;
        padding: 25px 30px;

        &-item {
            min-height: 65px;
            width: 100%;
            border-radius: 10px;
            background-color: white;
            display: flex;
            align-items: center;

            &:hover {
                background-color: RGBA(69, 84, 93, 0.08);
            }

            &:hover #trash {
                opacity: 1;
                transform: translateY(0px);
            }
        }

        &-icon {
            height: 100%;
            width: 100%;
            object-fit: contain;

            &-container {
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                background: white;
                box-shadow: 0px 0px 5px rgba(173, 173, 173, 0.5);
            }
        }
    }

    &-check {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background-color: RGB(155, 155, 155, 0.5);

        &-container {
            height: 20px;
            width: 20px;
        }

        &-icon {
            height: 8px;
            width: 11px;
            object-fit: contain;
        }
    }

    &-trash {
        height: 20px;
        width: 20px;
        object-fit: contain;
        cursor: pointer;
        opacity: 0;
        transform: translateY(-10px);
    }
}