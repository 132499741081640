.error-modal {
    &-container {
        height: 100vh;
        width: 100vw;
        position: fixed;
        left: 0px;
        top: 0px;
        z-index: 99999999;
    }

    &-mask {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0%;
        top: 0%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &-box {
        background-color: white;
        position: relative;
        overflow: hidden;
        min-width: 500px;

        &-header {
            width: 100%;
            min-height: 100px;
            background-color: RGB(46, 160, 180);
            box-sizing: border-box;
            padding: 15px;
        }
    }

    &-sub-container {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }

    &-button {
        height: 40px;
        width: 100px;
        background-color: RGB(46, 160, 180);
        border-radius: 5px;
        cursor: pointer;
    }
}