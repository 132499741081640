.MuiInputBase-root,
.MuiInputBase-root.Mui-focused {
  background: none !important;
}
.MuiInput-underline:before {
  content: '' !important;
}
.MuiInput-underline:after,
.MuiInput-underline:before {
  border-bottom: 0px !important;
}
.MuiNativeSelect-root {
  font-weight: 600 !important;
  font-family: 'Vietnam' !important;
  font-size: 14px !important;
}
.MuiOutlinedInput-input {
  padding: 8.51px !important;
  height: 36px;
}
.MuiNativeSelect-select.MuiNativeSelect-select {
  padding:5px;
  padding-right: 24px;
  position: relative;
  z-index: 2;
  height: 1.5876em;
  border:1px solid #DFDFDE;
  border-radius: 10px;
  width: unset;

}
.MuiNativeSelect-select.MuiNativeSelect-select:focus {
  padding:5px;
  padding-right: 24px;
  position: relative;
  z-index: 2;
  height: 1.5876em;
  border:1px solid #DFDFDE;
  border-radius: 10px;

}
select,
input[type='text'] {
  width: 100%;
  box-sizing: border-box;
}
.MuiSelect-selectMenu {
  width: 100px;
}

fieldset {
  width: 100%;
}
.MuiOutlinedInput-adornedStart {
  padding-left: 14px;
  height: 40px;
  margin: 10px;
}
.MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 32px;
  position: relative;
  z-index: 3;
}

