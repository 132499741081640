.none{
  display: none;
}

.flex {
  display: flex;

  &-1 {
    flex: 1;
  }

  &-2 {
    flex: 2;
  }

  &-3 {
    flex: 3;
  }

  &-4 {
    flex: 4;
  }

  &-5 {
    flex: 5;
  }

  &-6 {
    flex: 6;
  }

  &-7 {
    flex: 7;
  }

  &-8 {
    flex: 8;
  }

  &-9 {
    flex: 9;
  }

  &-10 {
    flex: 10;
  }

  &-11 {
    flex: 11;
  }

  &-12 {
    flex: 12;
  }

  &-13 {
    flex: 13;
  }

  &-14 {
    flex: 14;
  }

  &-15 {
    flex: 15;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.w-100{
  width:100%  !important;
}
.h-100{
  height:100vh;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-shrink-disable {
  flex-shrink: 0;
}

.flex-grow-disable {
  flex-grow: 0;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.horizontal-start {
  display: flex;
  justify-content: flex-start;
}

.horizontal-end {
  display: flex;
  justify-content: flex-end;
}

.horizontal-between {
  display: flex;
  justify-content: space-between;
}

.horizontal-even {
  display: flex;
  justify-content: space-evenly;
}

.horizontal-around {
  display: flex;
  justify-content: space-around;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.vertical-start {
  display: flex;
  align-items: flex-start;
}

.vertical-end {
  display: flex;
  align-items: flex-end;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;

  &-origin {
    @extend .absolute;
    left: 0%;
    top: 0%;
  }
}

.position-fixed {
  position: fixed;
  
  &-origin {
    @extend .position-fixed;
    left: 0%;
    top: 0%;
  }
}

.set-image {
  background-repeat: no-repeat;
  background-position: center;

  &--contain {
    @extend .set-image;
    background-size: contain;
  }

  &--cover {
    @extend .set-image;
    background-size: cover;
  }
}

.object-fit {
  &-contain {
    object-fit: contain;
  }
  &-cover {
    object-fit: cover;
  }
  &-fill {
    object-fit: fill;
  }
  &-none {
    object-fit: none;
  }
}

.full-container {
  height: 100vh;
  width: 100vw;
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-x-hidden {
    overflow-x: hidden;
  }
  &-y-hidden {
    overflow-x: hidden;
  }
  &-x-scroll {
    overflow-x: scroll;
  }
  &-y-scroll {
    overflow-y: scroll;
  }
  &-x-auto {
    overflow-x: auto;
  }
  &-y-auto {
    overflow-y: auto;
  }
}

.float-item {
  &-right{
    float: right;
  }

  &-left {
    float: left;
  }
}

.opacity {
  &-0 {
    opacity: 0;
  }

  &-5 {
    opacity: 0.05;
  }

  &-10 {
    opacity: 0.1;
  }

  &-15 {
    opacity: 0.15;
  }

  &-20 {
    opacity: 0.2;
  }

  &-25 {
    opacity: 0.25;
  }

  &-30 {
    opacity: 0.3;
  }

  &-35 {
    opacity: 0.35;
  }

  &-40 {
    opacity: 0.4;
  }

  &-45 {
    opacity: 0.45;
  }

  &-50 {
    opacity: 0.5;
  }

  &-55 {
    opacity: 0.55;
  }

  &-60 {
    opacity: 0.6;
  }

  &-65 {
    opacity: 0.65;
  }

  &-70 {
    opacity: 0.7;
  }

  &-75 {
    opacity: 0.75;
  }

  &-80 {
    opacity: 0.8;
  }

  &-85 {
    opacity: 0.85;
  }

  &-90 {
    opacity: 0.9;
  }

  &-95 {
    opacity: 0.95;
  }

  &-100 {
    opacity: 1;
  }
}

.z-index {
  &-9 {
    z-index: 9;
  }

  &-99 {
    z-index: 99;
  }

  &-999 {
    z-index: 999;
  }

  &-9999 {
    z-index: 9999;
  }

  &-99999 {
    z-index: 99999;
  }

  &-999999 {
    z-index: 999999;
  }
}