.renew-password {
    &-container {
        height: calc(100% - 127px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-input {
        width: 100%;
        background-color: rgba(0, 0, 0, 0);
        border-bottom: 1px solid #e1e1e1;
        margin-right: 10px;
    }

    &-eye {
        height: 100%;
        width: 100%;
        object-fit: contain;
        position: absolute;
        left: 0px;
        top: 0px;

        &-container {
            width: 22px;
            height: 22px;
            position: relative;
            flex-shrink: 0;
            cursor: pointer;
            padding: 0px;
        }
    }

    &-button {
        height: 45px;
        width: 100%;
        border-radius: 7px;
        background-color: rgb(46, 160, 180);
        position: relative;
        cursor: pointer;

        &-icon {
            height: 22px;
            width: 22px;
            object-fit: contain;
            position: absolute;
            left: 21px;
        }
    }
}
