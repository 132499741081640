.project-detail {
    &-input {
        &-container{
            height: 350px;
            width: 100%;
            border: solid 1px black;
        }

        &-header {
            height: 60px;
            width: 100%;
            box-sizing: border-box;
            border-bottom: solid 1px black;
            padding: 0px 25px;
        }

        &-body {
            height: calc(100% - 60px);
            width: 100%;
            box-sizing: border-box;
            padding: 25px;

            &-textarea {
                height: 100%;
                width: 100%;
                resize: none;
                border: none;
                background-color: rgba(0, 0, 0, 0);
                outline: none;

                &::placeholder {
                    color: #9b9b9b;
                }
            }
        }
    }

    &-radio-button {
        &-container {
            height: 25px;
            width: 25px;
            cursor: pointer;
        }

        &-border {
            height: 100%;
            width: 100%;
            background-color: #4a4a4a;
            border-radius: 12px;
        }

        &-center {
            height: 15px;
            width: 15px;
            border-radius: 15px;
            background-color: white;
        }
    }

    &-link {
        &-input {
            width: 300px;
            height: 40px;
            background-color: rgba(0, 0, 0, 0);
            border-bottom: solid 1px #9b9b9b;

            &::placeholder {
                color: #9b9b9b;
            }
        }
    }

    &-upload {
        &-default {
            height: 180px;
            width: 150px;
            cursor: pointer;

            &-icon {
                height: 75%;
                width: 75%;
                object-fit: contain;

                &-container {
                    height: 50px;
                    width: 50px;
                    border: solid 1px #9b9b9b;
                    border-radius: 5px;
                }
            }
        }

        &-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            
            &-container {
                height: 180px;
                width: 258px;
            }

            &-delete {
                height: 20px;
                width: 20px;
                object-fit: contain;
                
                &-container {
                    height: 44px;
                    width: 44px;
                    background-color: white;
                    border-radius: 22px;
                    position: absolute;
                    top: calc(0% - 22px);
                    left: calc(100% - 22px);
                    cursor: pointer;
                    border: 1px solid #2EA0B4;
                    box-sizing: border-box;
                }
            }
        }
    }

    &-draft {
        &-toolbar {
            border-bottom: solid 1px black;
        }

        &-wrapper{
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        &-editor {
            width: calc(100% - 15px);
            margin-left: 15px;
        }
    }
}