.fade-in-translate {
  animation: fade-in-translate 0.8s cubic-bezier(0.76, 0, 0.24, 1) both;
}

.fade-out-translate {
  animation: fade-out-translate 0.4s cubic-bezier(0.76, 0, 0.24, 1) both;
}

.fade-in-right {
	-webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) ;
          animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) ;
}

.fade-out-right {
	-webkit-animation: fade-out-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) ;
          animation: fade-out-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) ;
}

.fade-in-left {
	-webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.rotate-out-center {
	-webkit-animation: rotate-out-center 1.5s cubic-bezier(0.76, 0, 0.24, 1) both;
	        animation: rotate-out-center 1.5s cubic-bezier(0.76, 0, 0.24, 1) both;
}

.move-in-right {
	-webkit-animation: move-in-right 0.5s cubic-bezier(0.76, 0, 0.24, 1) both;
          animation: move-in-right 0.5s cubic-bezier(0.76, 0, 0.24, 1) both;
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-out-left {
	-webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.animatable-100 {
  transition-duration: 0.1s;
}

.animatable-200 {
  transition-duration: 0.2s;
}

.animatable-300 {
  transition-duration: 0.3s;
}

.animatable-400 {
  transition-duration: 0.4s;
}

.animatable-500 {
  transition-duration: 0.5s;
}

.animatable-600 {
  transition-duration: 0.6s;
}

.animatable-700 {
  transition-duration: 0.7s;
}

.animatable-800 {
  transition-duration: 0.8s;
}

.animatable-900 {
  transition-duration: 0.9s;
}

.animatable-1000 {
  transition-duration: 1s;
}

.ease-in-out-quart {
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
}

// key frames below 
@keyframes fade-in-translate {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fade-out-translate {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
}

@keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg);
  }
}

@keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg);
  }
}

@keyframes move-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}
