
.unit-document-dropdown {
    &-container {
        width: 200px;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0px 0px 7px RGBA(147, 147, 147, 0.5);
        position: absolute;
        top: calc(100% + 20px);
        overflow: hidden;
        transform-origin: top;
    }

    &-item {
        min-height: 40px;
        height: auto;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-icon {
        height: 15px;
        width: 15px;
        object-fit: contain;
        cursor: pointer;
    }
}