/* [NOTE]: Pease remove this file. This has been added to  `styles/global.css.js`*/

:root {
  --scaleHeight: 1;
  --scale: 1;
  --darkBleu:#45545D;
  --gray:#9B9B9B;
  --lightGray:#F0F1F2;
  --green:#2EA0B4;
  --success-green:#1FA687;
  --red:#D40000;
}
