.message-contact {
    &-container {
        height: 89px;
        width: 100%;
        cursor: pointer;

        &:hover {
            background-color: rgb(240, 241, 242);
        }
    }

    &-delete-btn {
        position: absolute !important;
        right: 0;
        top: 0;
    }
}