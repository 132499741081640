.MenuList{
    height: calc(100% - 5rem);
}
.icon-img{
    height: 15px;
}
.logo-footer{
    height: 40px;
    padding: 10px;
    position: relative;
    background: rgb(244, 246, 249);

}
.listitem{
    height: 50px;
    padding: 10px;
    margin: 18px 0px !important;
}
.carret{
    left: 20px;
    font-size: 10px;
}
.MuiListItemIcon-root{
    min-width: 30px !important;
}
.MuiListItem-root,.MuiListItem-root:hover,.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    height: 30px;
    background-color: rgba(0, 0, 0, 0.08);
}
.MuiListItemText-root span{
    font-family: 'Vietnam';
    font-size: 14px;
    font-weight: 600;
    line-height: 2.143;
    color:  rgb(69, 84, 93);
}