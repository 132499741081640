.checkbox {
  height: 20px;
  width: 20px;

  &-container {
    cursor: pointer;
    padding: 0px;
  }

  &-icon {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
  }
}

.checkbox-round {
  &-container {
    height: 20px;
    width: 20px;
    padding: 0px;
    display: flex;
    cursor: pointer;
  }

  &-main {
    height: 16px;
    width: 16px;
    border: solid 2px rgb(69, 84, 93);
    border-radius: 10px;
    background-color: white;
  }

  &-center {
    width: 12px;
    height: 12px;
    border-radius: 7px;
    background-color: rgb(46, 160, 180);
  }
}