.projecten {
  &-container {
    width: calc(100vw - 300px);
    height: calc(100vh - 128px);
  }

  &-button {
    height: 36px;
    width: 142px;
    border-radius: 5px;
    cursor: pointer;

    &-blue {
      @extend .projecten-button;
      background-color: rgb(46, 160, 180);
    }

    &-grey {
      @extend .projecten-button;
      background-color: rgb(240, 241, 242);
    }
  }

  &-sub-container {
    width: 100%;
    overflow-x: scroll;
    min-height: 65vh;
  }

  &-grid {
    display: flex;
    flex-direction: column;
    width: fit-content;

    &-container {
      height: 432px;
      margin-right: 10px;

      &-one {
        @extend .projecten-grid-container;
        width: 938px;
        height: fit-content;
      }

      &-two {
        @extend .projecten-grid-container;
        width: 578px;
        height: fit-content;
      }

      &-three {
        @extend .projecten-grid-container;
        width: 597px;
        height: fit-content;
      }

      &-four {
        @extend .projecten-grid-container;
        width: 553px;
        height: fit-content;
      }

      &-five {
        @extend .projecten-grid-container;
        width: 897px;
        height: fit-content;
      }

      &-border {
        border: solid 1px rgb(223, 223, 222);
      }

      &-border-half {
        border-left: solid 1px rgb(223, 223, 222);
        border-right: solid 1px rgb(223, 223, 222);
      }
    }

    &-header {
      &-box {
        height: 36px;
        width: 100%;
        border-bottom: solid 1px rgb(223, 223, 222);
        background-color: RGB(248, 248, 248);
      }
    }

    &-row {
      height: 36px;
      width: 100%;
      display: flex;
      flex-direction: row;

      &-border {
        @extend .projecten-grid-row;
        border-bottom: solid 1px rgb(223, 223, 222);
      }
    }

    &-box {
      box-sizing: border-box;
      padding: 11px;

      &-one {
        width: 91px;
        height: 100%;
        @extend .projecten-grid-box;

        &-dropdown {
          @extend .projecten-grid-box-one;
          padding: 0px;
        }
      }

      &-two {
        width: 90px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-three {
        width: 85px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-four {
        width: 150px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-five {
        width: 97px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-six {
        width: 133px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-seven {
        width: 116px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-eight {
        width: 152px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-nine {
        width: 160px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-ten {
        width: 135px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-eleven {
        width: 186px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-twelve {
        width: 169px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-thirteen {
        width: 130px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-fourteen {
        width: 220px;
        height: 100%;
        @extend .projecten-grid-box;
      }

      &-dropdown {
        border-radius: 5px;
        width: 100%;
        background-color: white;
        border: solid 1px rgb(204, 204, 204);
        position: absolute;
        top: 100%;
        left: 0%;
        z-index: 9999;
        overflow: hidden;

        &-item {
          height: 45px;
          width: 100%;
          box-sizing: border-box;
          padding: 11px;

          &:hover {
            background-color: rgb(223, 223, 222);
          }
        }
      }

      &-input {
        width: 100%;
        background-color: transparent;

        &:focus {
          border-bottom: solid 1px RGB(69, 84, 93);
        }
      }

      &-per {
        &-container {
          height: 20px;
          width: 45px;
          border-radius: 10px;
          border: solid 1px RGB(69, 84, 93);
        }
      }

      &-modal {
        &-container {
          height: 85px;
          width: 160px;
          border-radius: 5px;
          background-color: white;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0px;
          top: 100%;
          border: solid 1px RGB(204, 204, 204);
          box-sizing: border-box;
          padding: 7px 4px;
          transform-origin: top;
        }

        &-input {
          height: 27px;
          width: 51px;
          border: solid 1px RGB(204, 204, 204);
          border-radius: 5px;
          text-align: center;
        }

        &-button {
          height: 27px;
          width: 66px;
          border-radius: 5px;
          cursor: pointer;

          &-active {
            @extend .projecten-grid-box-modal-button;
            background-color: RGB(46, 160, 180);
          }

          &-inactive {
            @extend .projecten-grid-box-modal-button;
            background-color: RGB(240, 241, 242);
          }
        }
      }
    }

    &-border {
      &-right {
        border-right: solid 1px rgb(223, 223, 222);
      }

      &-left {
        border-left: solid 1px rgb(223, 223, 222);
      }
    }

    &-total {
      border: solid 1px rgb(223, 223, 222);

      &-container {
        width: 100%;
        height: 60px;
        margin-top: 10px;
      }
    }

    &-upload-button {
      height: 60px;
      background-color: rgb(240, 241, 242);
      border-radius: 5px;
      cursor: pointer;

      &-one {
        @extend .projecten-grid-upload-button;
        width: 97px;
      }

      &-two {
        @extend .projecten-grid-upload-button;
        width: 355px;
      }
    }
  }

  &-alles {
    &-row {
      width: 386px;
    }
  }
}
.for-height-over-flow {
  display: flex;
  flex-direction: column;
}

.akten-dropdown {
  &-container {
    width: 100%;
    height: 300px;
    position: absolute;
    top: calc(100% + 10px);
    left: -2px;
    border-radius: 5px;
    border: solid 2px RGB(223, 223, 222);
    background-color: white;
    z-index: 99999;
    transform-origin: top;
    overflow: hidden;
  }

  &-item {
    height: 40px;
    width: 100%;
    background-color: white;
    cursor: pointer;

    &:hover {
      background-color: rgb(240, 241, 242);
    }
  }
}
