.mainstyle{
    background-color: white;
    display: flex; 
    justify-content: center;
}
.submainstyle{
    width: 552px; 
    background-color: white;
}
.styleimage{ 
    height: 64px;
    display: block;
    object-fit: contain;
    margin-top: 13px;
    margin-left: auto;
    margin-right: auto;
}
.stylepara{
    padding: 20px;
    height: 84px;
    color: DarkBleu #45545D;
    font-size: 14px;

}
.stylebutton{
    background-color:#2EA0B4;
    margin-top: 20px;
    margin-left: 183px; 
    border: none;
    height: 45px;
    width: 177px;
    border-radius: 5px;
    color: white;
    margin-bottom: 10px;
}
.newpara{
    padding: 20px; 
    height: 147px;
    width: 496px; 
    color: DarkBleu #45545D;
    margin-bottom: 32px;
    font-size: 14px;
}
.innerfooter{
    background-color:#45545D;
    width: 552px;
    height: 142px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newparagraph{
    padding: 20px;
    height: 84px;
    width: 195px;
    font-size: 14px;
    color: DarkBleu #45545D;
}

.innerbutton{
    background-color: #2EA0B4;
    margin-left: 350px;
    position: relative;
    bottom: 70px;
    height: 45px;
    width: 177px;
    border-radius: 5px;
    color:white;
    border: none
}
.parstyle{
    margin-top: 10px;
}

