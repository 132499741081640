.tab-eenheid {
    &-container {
        height: calc(100vh - 312px);
        width: 100%;
    }

    &-box {
        width: 301px;
        border-radius: 10px;
        border: solid 1px RGB(204, 204, 204);
        background-color: white;
        box-sizing: border-box;
        padding: 12px 9px;

        &-one {
            @extend .tab-eenheid-box;
            height: 227px;
        }

        &-two {
            @extend .tab-eenheid-box;
            height: 217px;
        }
    }
}