@mixin round-button-one($width, $color) {
  border-radius: 500px;
  border: solid 2px $color;
  background-color: rgba(0, 0, 0, 0);
  width: $width;
}

@mixin round-button-filled($width, $color) {
  border-radius: 500px;
  background: $color;
  width: $width;
  cursor: pointer;
}

@mixin square-button-flat($width, $color, $radius) {
  background: $color;
  border-radius: $radius;
  width: $width;
  cursor: pointer;
}

@mixin square-button-sharp($width, $color) {
  background-color: $color;
  width: $width;
  cursor: pointer;
}

@mixin square-button-transparent($width, $color) {
  border: solid 2px $color;
  background-color: rgba(0, 0, 0, 0);
  width: $width;
  cursor: pointer;
}
