.tab-historiek {
    &-container {
        height: calc(100vh - 312px);
        width: 100%;
        overflow-y: auto;
    }

    &-card {
        height: 102px;
        width: 100%;
        background-color: RGB(244, 246, 249);
        box-sizing: border-box;
        padding: 21px 18px;
    }

    &-calender {
        height: 18px;
        width: 20px;
        object-fit: contain;
    }

    &-mail {
        width: 20px;
        height: 16px;
        object-fit: contain;
    }

    &-person {
        width: 15px;
        height: 20px;
        object-fit: contain;
    }

    &-modal {
        &-container {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: rgba(0, 0, 0, 0.5);
            left: 0px;
            top: 0px;
            z-index: 999999;
        }
    }
}