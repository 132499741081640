
.aankoper {
    input {
        width: 200px; 
        height: 30px; 
        border-bottom: 1px solid rgb(225, 225, 225);
    }
    
    &-button {
        height: 40px;
        width: 175px;
        border-radius: 5px;
        border: solid 1px #2eb442;
        z-index: 9999;
        transform-origin: top;

        &-text {
            color: #2eb442;
        }
    }

    &-submit {
        height: 40px;
        width: 175px;
        border-radius: 5px;
        background-color: RGB(46, 160, 180);
        z-index: 9999;
        transform-origin: top;
        cursor: pointer;
    }
}

.aankoper-modal {
    &-container {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 999999;
    }

    &-sub-container {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &-message {
        border-radius: 10px;
        padding: 25px;
        background-color: white;
    }

    &-button {
        height: 40px;
        width: 75px;
        border-radius: 5px;
        cursor: pointer;

        &-primary {
            @extend .aankoper-modal-button;
            background-color: RGB(46, 160, 180);
        }

        &-secondary {
            @extend .aankoper-modal-button;
            background-color: #B4422E;
        }
    }
}