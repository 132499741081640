.message-panel {
    &-container {
        width: 100%;
        height: calc(100vh - 128px);
    }

    &-dropdown {
        height: 36px;
        width: 171px;
        box-sizing: border-box;
        padding: 0px 9px;
        background-color: white;
        border: solid 1px rgb(204, 204, 204);
        border-radius: 5px;

        &-container {
            width: 171px;
            height: auto;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            position: relative;
        }

        &-expand {
            width: 386px;
            background-color: white;
            border: solid 1px rgb(204, 204, 204);
            border-radius: 5px;
            position: absolute;
            top: 36px;
            left: 0%;
            overflow: hidden;
            transform-origin: top;
            z-index: 9999;

            &-item {
                height: 36px;
                width: 100%;
                box-sizing: border-box;
                padding: 0px 9px;

                &:hover {
                    background: RGB(234, 234, 234);
                }
            }
        }

        &-building {
            &-icon {
                height: 15px;
                width: 15px;
                object-fit: contain;
                flex-shrink: 0;
            }
        }

        &-arrow {
            &-icon {
                height: 10px;
                width: 10px;
                object-fit: contain;
                flex-shrink: 0;
            }
        }
    }

    &-input {
        height: 36px;
        width: 171px;
        border-radius: 5px;
        border: solid 1px rgb(204, 204, 204);
        background-color: white;
    }

    &-list {
        &-container {
            height: calc(100% - 57px);
            width: 100%;
            border-radius: 10px;
            border: solid 1px rgb(212, 212, 212);
            background: white;
            padding: 12px 19px;
            box-sizing: border-box; 
            overflow-y: auto;
        }
    }

    &-messages {
        &-container {
            height: calc(100% - 57px);
            width: 100%;
            border-radius: 10px;
            border: solid 1px rgb(212, 212, 212);
            background: white;
        }

        &-list {
            &-container {
                height: calc(100% - 78px);
                width: 100%;
                padding: 19px 28px;
                padding-bottom: 0px;
                box-sizing: border-box; 
            }

            &-heading {
                height: 49px;
                width: 100%;
                border-radius: 5px;
                background: rgb(240, 241, 242);
            }

            &-view {
                height: calc(100% - 49px);
                width: 100%;
                overflow-y: auto;
            }
        }

        &-input {
            &-container {
                height: 78px;
                width: 100%;
                padding: 19px 28px;
                border: solid 1px rgb(204, 204, 204);
                border-radius: 0 0 10px 10px;
                box-sizing: border-box; 
                display: flex;
            }

            &-box {
                width: 100%;
                height: 45px;
                border-radius: 5px;
                background-color: rgb(240, 241, 242);
                display: flex;
            }

            &-emoji {
                height: 45px;
                width: 45px;
                object-fit: contain;
                cursor: pointer;

                &-container {
                    height: 300px;
                    width: 400px;
                    position: absolute;
                    bottom: calc(100% + 25px);
                    transform-origin: bottom;
                }
            }

            &-button {
                height: 45px;
                width: 45px;
                border-radius: 5px;
                cursor: pointer;
                background-color: rgb(46, 160, 180);
            }
        }
    }

    &-input-placeholder {
        &::placeholder {
            color: #9b9b9b;
        }
    }
}