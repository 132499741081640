.error-message {

  &-container {
    min-width: 300px;
    padding: 25px;
    border-radius: 10px;
    background-color: white;
  }

  &-button {
    height: 40px;
    border-radius: 5px;
    width: 250px;
    background: #2EA0B4;
    cursor: pointer;
    margin-top: 25px;
  }
}
