.dropdown-menu {
    &-container {
        width: 100px;
        background-color: white;
        border-radius: 5px;
        position: absolute;
        top: calc(100% + 10px);
        border: solid 1px RGB(204, 204, 204);
        transform-origin: top;
        z-index: 999;
    }

    &-item {
        height: 40px;
        width: 100%;

        &:hover {
            background-color: RGBA(69, 84, 93, 0.08);
        }
    }
}