.document-panel {
    &-container {
        width: 100%;
        height: calc(100vh - 128px);
    }

    &-dropdown {
        height: 36px;
        width: 171px;
        border-radius: 5px;
        border: solid 1px rgb(204, 204, 204);
        background-color: white;
    }

    &-input {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0);

        &-container {
            height: 34px;
            width: 171px;
            border-radius: 5px;
            border: solid 1px rgb(204, 204, 204);
            background-color: white;
            position: relative;
            // top:2px;
        }

        &-icon {
            height: 36px;
            width: 36px;
        }
    }

    &-folder {
        &-icon {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
    }

    &-main {
        &-container {
            height: calc(100% - 57px);
            width: 100%;
            border-radius: 10px;
            background-color: white;
            border: solid 1px rgb(212, 212, 212);
        }

        &-divider {
            border-right: solid 1px rgb(212, 212, 212);
        }

        &-header {
            height: 54px;
            width: 100%;

            &-icon {
                width: 20px;
                height: 20px;
                object-fit: contain;
                object-position: bottom;
            }
        }

        &-list {
            &-container {
                height: calc(100% - 54px);
                width: 100%;
                box-sizing: border-box;
                overflow-y: auto;
                overflow-x: hidden;
            }

            &-item {
                height: 30px;
                width: 100%;
                transition-duration: 0.4s;
                background-color: white;
                cursor: pointer;

                &:hover {
                    background-color: rgba(148, 150, 152, 0.17);
                }
            }

            &-button {
                height: 36px;
                border: solid 2px rgb(69, 84, 93);
                border-radius: 5px;
                padding: 0px 10px;
                cursor: pointer;
                background: white;

                &:hover {
                    background: rgb(69, 84, 93);
                }

                &:hover p {
                    color: white;
                }
            }

            &-document {
                &-item {
                    min-height: 90px;
                    border-radius: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    background: white;

                    &:hover {
                        background-color: rgba(69, 84, 93, 0.08);
                    }
                }

                &-icon {
                    height: 40px;
                    width: 40px;
                    object-fit: contain;
                    
                    &-container {
                        height: 40px;
                        width: 40px;
                        border-radius: 7px;
                        background-color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        &-type {
            height: auto;
            width: 100%;
        }
    }

    &-modal {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        &-container {
            height: 100vh;
            width: 100vw;
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 999999;
        }

        &-box {
            height: 301px;
            width: 659px;
            background-color: white;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 12px;
        }

        &-close {
            height: 35px;
            width: 35px;
            object-fit: contain;
            cursor: pointer;
        }
        
        &-add {
            height: 20px;
            width: 20px;
            object-fit: contain;
        }

        &-input {
            height: 45px;
            width: 431px;
            border-radius: 10px;
            border: solid 1px rgb(204, 204, 204);
            background-color: white;
            text-indent: 19px;
        }

        &-button {
            height: 45px;
            width: 431px;
            border-radius: 5px;
            background-color: rgb(46, 160, 180);
            cursor: pointer;
        }
    }
}

.document-dropzone {
    &-container {
        height: calc(100% - 30px);
        width: 100%;
    }
}