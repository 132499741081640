
.lineHeight36{
    line-height: 36px;
}
.custom-dropdown {
    &-container {
        height: 20px;
        cursor: pointer;
        position: relative;
    }

    &-arrow {
        height: 10px;
        width: 15px;
        object-fit: contain;
        object-position: center;
    }

    &-expand {
        max-height: 300px;
        width: 100%;
        border-radius: 5px;
        border: solid 1px RGB(204, 204, 204);
        background-color: white;
        position: absolute;
        top: calc(100% + 5px);
        overflow-y: auto;
        overflow-x: hidden;
        transform-origin: top;

        &-item {
            height: 45px;
            width: 100%;
            box-sizing: border-box;
            padding: 0px 5px;
            display: flex;
            align-items: 'center';

            &:hover {
                background-color: RGB(240, 241, 242);
            }
        }
    }
}

.tab-informatie {
    &-minus {
        height: 15px;
        width: 15px;
        object-fit: contain;
        cursor: pointer;
    }

    &-add {
        height: 26px;
        width: auto;
        box-sizing: border-box;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 5px;
        background-color: RGB(240, 241, 242);
    }

    &-number {
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            -moz-appearance: textfield;
        }

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            -moz-appearance: textfield;
        }
    }
}

#date-picker-inline-helper-text {
    display: none;
}

.show-red-color {
  color: #ef4b4b !important;
}
