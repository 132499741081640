.font-color-grey{
    color: rgb(155, 155, 155);
}
.profile {
    
    &-container {
        // height: calc(100vh - 224px);
        // width: 100%;
        background-color: white;
        overflow-y: auto;
       
    }

    &-details {

        &-container {
            // height: calc(442px - 38px);
            // width: 80%;
            // border: solid 1px rgb(196, 203, 213);
            background-color: white;
            border-radius: 10px;
            // padding: 19px 33px;
        }

        &-password {
            width: 20%;

            &-container {
                width: 100%;
                display: flex;
                align-items: center;
            }

            &-show {
                width: 22px;
                height: 22px;
                cursor: pointer;
            }

            &-renew {
                padding: 5px 10px;
                border-radius: 5px;
                background-color: rgb(240, 241, 242);
                cursor: pointer;

                &:hover {
                    background-color: #45545D;
                }

                &:hover p {
                    color: white;
                }
            }
        }
    }

    &-preferences {

        &-container {
            width: 80%;
            height: calc(201px - 38px);
            border: solid 1px rgb(196, 203, 213);
            background-color: white;
            border-radius: 10px;
            padding: 19px 33px;
        }
    }

    &-purchases {

        &-container {
            height: calc(729px - 38px);
            width: 80%;
            border: solid 1px rgb(196, 203, 213);
            background-color: white;
            border-radius: 10px;
            padding: 19px 33px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .profile {
        &-container {
            width: 95%;
            margin-left: 2.5%;
        }

        &-details {
            &-container {
                height: auto;
            }
        }

        &-preferences {
            &-container {
                height: auto;
            }
        }

        &-purchases {
            &-container {
                height: auto;
            }
        }
    }
}
.MuiTab-root{
    padding: 0px !important;
}
.MuiTabPanel-root{
    padding:  35px 0px !important;
}
.MuiTab-wrapper{
    font-family: 'Vietnam';
    text-transform: none;
    font-weight : 600  !important;
    font-size: 16px;
}
.Mui-selected{
    
    .MuiTab-wrapper{
        color:var(--green);
       
    }
    
}
.MuiTabs-flexContainer{
    border-bottom: 1px solid rgba(69, 84, 93, 0.5);
}
.MuiTabs-indicator{
    background-color:var(--green) !important;
}
@media (min-width: 600px)
{
    .MuiTab-root {
        min-width: 140px  !important;
    }
}


.MuiOutlinedInput-input::placeholder{
color:'#000'
}
::-webkit-input-placeholder { /* Edge */
    color: #000 ;
  }
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:  #000 ;
    font-weight: 800;
  }
::placeholder {
    color:  #000 ;
    font-weight: 400;
    opacity: 1 !important;
  }